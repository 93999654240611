.card {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    /*border-radius: 10px;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);*/
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the height of the container to fill the viewport */

}

.card img {
    width: 25%;
    height: auto;
    border-radius: 10px;
    max-width: 26%;
    display: block;
    margin: 0 auto;
}

.card h1 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.card h2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
}

.social-links-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.contact-links-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.social-links {
    display: list-item;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.social-links li {
    margin: 0 10px;
    padding: 10px;
}

.link-title {
    font-size: 26px;
}

.social-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 40px;*/
    height: 40px;
    border-radius: 25%;
    background-color: #fff;
    color: #555;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background-color: #555;
    color: #fff;
}

.contact-links {
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.contact-links li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.contact-links li i {
    margin-right: 10px;
    width: 20px;
    text-align: center;
    font-size: 16px;
}

.contact-links li a {
    color: #555;
    text-decoration: none;
    transition: all 0.3s ease;
}

.contact-links li a:hover {
    color: #000;
}

@media screen and (max-width: 767px) {
    .card {
        /*margin: 0 20px;*/
    }
}

@media screen and (max-width: 576px) {
    .card h1 {
        font-size: 20px;
    }

    .card h2 {
        font-size: 16px;
    }

    .social-links a {
        /*width: 30px;*/
        height: 30px;
        font-size: 14px;
    }

    .contact-links li i {
        width: 16px;
        font-size: 14px;
    }

    .contact-links li span {
        font-size: 14px;
    }
}
